import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "Layouts/default"
import Config from "Data"
import { SEO, Utils, FeaturedSection, FooterCTA, LoadingSpinner } from "Components"
import { Gap } from "Styles"
import { Button } from "Components"
import { IconContext } from "react-icons"
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa"

const ManagePreferences = () => {
  let [loading, setLoading] = useState(false)
  let [leadId, setLeadId] = useState()
  let [dnc, setDNC] = useState(false)
  let [newsletter, setNewsletter] = useState(false)

  const onSubmit = async (values) => {
    setLoading(true)
    postData(Utils.endpoints.manage, values.data).catch((error) => console.error(error))
  }

  function postData(url = "", data = {}) {
    return fetch(url, {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(data),
    })
      .then(() => {
        setLoading(false)
      })
      .catch((error) => console.error("Error:", error))
  }

  // function saveUserPreferences(params) {
  //   console.log("saveUserPreferences")
  //   localStorage.setItem("UserPreferences", JSON.stringify(params))
  // }

  // const restoreState = () => {
  //   // if (!localStorage.getItem("Dashboard-Workflow")) {
  //   //   console.log("no columns state to restore by, you must save state first")
  //   //   return
  //   // }
  //   // gridColumnApi.applyColumnState({
  //   //   state: JSON.parse(localStorage.getItem("Dashboard-Workflow")),
  //   //   applyOrder: true,
  //   // })
  //   // console.log("column state restored")
  // }

  useEffect(() => {
    setLeadId(Utils.getUrlParam("id"))
    setDNC(Utils.getUrlParam("dnc"))
    setNewsletter(Utils.getUrlParam("newsletter"))
    // let userPreferences = {
    //   LeadId: Utils.getUrlParam("id") ? Utils.getUrlParam("id") : Utils.getLocalStorage("id"),
    //   DNC: Utils.getUrlParam("dnc") ? Utils.getUrlParam("dnc") : Utils.getLocalStorage("dnc"),
    //   Newsletter: Utils.getUrlParam("newsletter")
    //     ? Utils.getUrlParam("newsletter")
    //     : Utils.getLocalStorage("newsletter"),
    // }
    // saveUserPreferences(userPreferences)
  }, [])
  return (
    <>
      {loading && <LoadingSpinner />}
      <Layout
        locale={"en-us"}
        region={"us"}
        simple={true}
        sticky={true}
        transparentHeader={false}
        transparentFooter={true}>
        <SEO
          pathname="/thank-you"
          title={`Manage Preferences | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={`We've received your request. Our team will review the request and get in touch soon.`}
        />
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <Gap />
        <Gap />
        <Gap />

        <FeaturedSection
          dark={false}
          title={`Manage Preferences`}
          subtitle={`Please update your prefences below:`}
          cta_url1_text={``}
          cta_url1={``}
          page={`Manage Preferences page`}>
          <hr />
          {/* <p>
            To stop recieving emails from <b>EVENT NAME HERE</b>
          </p>
          <Button
            onClick={() =>
              postData({
                url: "http://api.cxoevents.com/manage-preferences/dnc",
                data: {
                  LeadId: leadId,
                  DNC: dnc,
                },
              })
            }
            className="btn"
            style={{ width: "110px" }}
            track={"Unsubscribe Event Name"}
            title={`Unsubscribe Event Name`}
            target="_blank">
            Unsubscribe
          </Button>
          <br />
          <hr /> */}
          <p>
            To{" "}
            <b>
              {!newsletter && "Stop"}
              {newsletter && "Start"}
            </b>{" "}
            recieving emails from our <b>Newsletter</b>
          </p>
          <Button
            onClick={() => {
              setNewsletter(!newsletter)
              onSubmit({
                data: {
                  Type: "newsletter",
                  LeadId: leadId,
                  Newsletter: Boolean(newsletter),
                },
              })
            }}
            className="btn"
            style={{ width: "110px" }}
            track={"Unsubscribe Newsletter"}
            title={`Unsubscribe Newsletter`}
            target="_blank">
            {!newsletter && (
              <span>
                <IconContext.Provider value={{ className: `svg-icon inline ${Boolean(!newsletter)}` }}>
                  <FaTimesCircle /> Opt out
                </IconContext.Provider>
              </span>
            )}
            {newsletter && (
              <span>
                <IconContext.Provider value={{ className: `svg-icon inline ${Boolean(!newsletter)}` }}>
                  <FaCheckCircle /> Opt in
                </IconContext.Provider>
              </span>
            )}
          </Button>
          <br />
          <hr />
          <p>
            To{" "}
            <b>
              {dnc && "Stop"}
              {!dnc && "Start"}
            </b>{" "}
            recieving emails from <b>All Communications</b>
          </p>
          <Button
            className="btn btn-primary"
            onClick={() => {
              setDNC(!dnc)
              onSubmit({
                data: {
                  Type: "dnc",
                  LeadId: leadId,
                  DNC: Boolean(dnc),
                },
              })
            }}
            style={{ width: "110px" }}
            track={"All Communications"}
            title={`All Communications`}
            target="_blank">
            {dnc && (
              <span>
                <IconContext.Provider value={{ className: `svg-icon inline ${Boolean(dnc)}` }}>
                  <FaTimesCircle /> Opt out
                </IconContext.Provider>
              </span>
            )}
            {!dnc && (
              <span>
                <IconContext.Provider value={{ className: `svg-icon inline ${Boolean(dnc)}` }}>
                  <FaCheckCircle /> Opt in
                </IconContext.Provider>
              </span>
            )}
          </Button>
          <br />
        </FeaturedSection>

        <FooterCTA
          dark={false}
          title="Got questions?"
          subtitle={`Get in touch directly by sending us an email at ${Config.contact.email} or give us a call:`}
          cta_url1={`tel:${Config.contact.phone.us}`}
          cta_url1_text={`${Config.contact.phone.us}`}
          cta_url2={`tel:${Config.contact.phone.uk}`}
          cta_url2_text={`${Config.contact.phone.uk}`}
          page={`Thank you page`}
        />
      </Layout>
    </>
  )
}

export default ManagePreferences
